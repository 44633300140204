import Vue from 'vue'
import VueRouter from 'vue-router'

import { i18n } from "/src/i18n";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/old',
            name: 'oldHome',
            component: () => import('@/views/HomeView.vue'),
            meta: {
                title: import.meta.env.VITE_PRODUCT_NAME,
            },
        },
        {
            path: '/',
            name: 'Onchainpay',
            component: () => import('@/views/HomeViewNew.vue'),
            meta: {
                title: import.meta.env.VITE_PRODUCT_NAME,
            },
            redirect: { name: 'MySubscriptions' },
            children: [
                {
                    path: 'subscriptions',
                    name: 'MySubscriptions',
                    components: {
                        default: () => import(/* webpackChunkName: "MySubscriptions" */ '@/views/MySubscriptions.vue'),
                    },
                    meta: {
                        title: i18n.t('main-menu.titles.subscriptions'),
                        menuTitle: i18n.t('main-menu.titles.subscriptions'),
                        icon: 'subscriptions'
                    },
                    children: [
                        {
                            path: 'connect/:linkId?',
                            name: 'SubscriptionConnect',
                            components: {
                                default: () => import(/* webpackChunkName: "SubscriptionConnect" */ '@/views/SubscriptionConnect.vue'),
                            },
                            meta: {
                                title: i18n.t('main-menu.titles.subscriptions'),
                                menuTitle: i18n.t('main-menu.titles.subscriptions'),
                                icon: 'subscriptions'
                            },
                        }
                    ]
                },
                // {
                //     path: 'subscriptions/connect',
                //     name: 'SubscriptionConnect',
                //     components: {
                //         default: () => import(/* webpackChunkName: "MySubscriptions" */ '@/views/SubscriptionConnect.vue'),
                //     },
                //     meta: {
                //         title: i18n.t('main-menu.titles.subscriptions'),
                //         menuTitle: i18n.t('main-menu.titles.subscriptions'),
                //         icon: 'subscriptions'
                //     },
                // },
                {
                    path: 'providers',
                    name: 'MyProviders',
                    components: {
                        default: () => import(/* webpackChunkName: "MySubscriptions" */ '@/views/MySubscriptions.vue'),
                    },
                    meta: {
                        title: i18n.t('main-menu.titles.providers'),
                        menuTitle: i18n.t('main-menu.titles.providers'),
                        icon: 'providers'
                    },
                },
                {
                    path: 'history',
                    name: 'History',
                    components: {
                        default: () => import(/* webpackChunkName: "MySubscriptions" */ '@/views/MySubscriptions.vue'),
                    },
                    meta: {
                        title: i18n.t('main-menu.titles.history'),
                        menuTitle: i18n.t('main-menu.titles.history'),
                        icon: 'history'
                    },
                },
                {
                    path: 'settings',
                    name: 'Settings',
                    components: {
                        default: () => import(/* webpackChunkName: "MySubscriptions" */ '@/views/MySubscriptions.vue'),
                    },
                    meta: {
                        title: i18n.t('main-menu.titles.settings'),
                        menuTitle: i18n.t('main-menu.titles.settings'),
                        icon: 'settings'
                    },
                },
                {
                    path: 'connect/:linkId',
                    name: 'connect',
                    component: () => import('@/views/ConnectView.vue'),
                    meta: {
                        title: 'Connect to ...',
                    },
                },
            ],
        },
        {
            path: '*',
            name: 'not-found',
            component: () => import('@/views/PageNotFoundView.vue'),
            meta: {
                title: '404 Not found',
            },
        },
    ],
    scrollBehavior: function (to, _from, savedPosition) {
        const scrollBlock = document.body;

        if (scrollBlock) {
            scrollBlock.scrollTo(0, 0);
        }
    }
});

export default router

router.afterEach((to) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = import.meta.env.VITE_PRODUCT_NAME;
    }
});
