export enum EWeb3Wallet {
    METAMASK,
    TRUSTWALLET,
    COINBASE,
    TRONLINK,
}

export enum EWeb3Network {
    ETH = 1, // chainId: 1
    BSC = 56, // chainId: 56
    TRON = -1, // no has chainId
}