import {defineStore} from "pinia";

import {ApiClient} from "@/stores/ApiUtils";

import {CurrenciesStore} from "@/stores/Currencies.store";
import {ControllerWeb3Store, EWeb3State} from "@/stores/ControllerWeb3.store";
import {MerchantStore} from "@/stores/Merchant.store";

let promiseQueue = Promise.resolve();
let debounceTimer = 0;

export const SubscriberStore = defineStore({
    id: 'subscriber',

    state: (): IState => {
        return {
            state: ESubscriberState.NONE,

            id: null,
            address: null,
            network: null,
            email: null,
        };
    },

    getters: {
        subscriber(state) {
            if(state.state !== ESubscriberState.DONE) {
                return null;
            }

            return {
                id: state.id!,
                address: state.address!,
                network: state.network!,
                email: state.email,
            };
        },
    },

    actions: {
        login() {
            clearTimeout(debounceTimer);

            debounceTimer = setTimeout(() => {
                promiseQueue = promiseQueue
                    .then(() => this._login());
            }, 500) as any as number;
        },

        clear() {
            this.id = null;
            this.address = null;
            this.network = null;
            this.email = null;
        },

        wait() {
            return promiseQueue;
        },

        async _login(): Promise<void> {
            this.state = ESubscriberState.PENDING;

            const currenciesStore = CurrenciesStore();
            const web3Store = ControllerWeb3Store();

            await currenciesStore.wait();

            if(web3Store.state !== EWeb3State.CONNECTED) {
                this.state = ESubscriberState.NONE;

                return;
            }

            const networkId = currenciesStore.getServiceNetwork(web3Store.network)?.id;

            if(!networkId) {
                await new Promise(r => setTimeout(r, 1000));

                return this._login();
            }

            try {
                const subscriberRes = await ApiClient.get(`/subscriber/${networkId}/${web3Store.account}`);

                if(!subscriberRes.data?.success) {
                    await new Promise(r => setTimeout(r, 1000));

                    return this._login();
                }

                this.id = subscriberRes.data.result.id;
                this.address = subscriberRes.data.result.address;
                this.network = subscriberRes.data.result.networkId;
                this.email = subscriberRes.data.result.email;

                this.state = ESubscriberState.DONE;

                const merchantStore = MerchantStore();

                await merchantStore._loadBillings();
            } catch (e) {
                await new Promise(r => setTimeout(r, 1000));

                return this._login();
            }
        }
    },
});

export enum ESubscriberState {
    NONE,
    PENDING,
    DONE,
}

interface IState {
    state: ESubscriberState;

    id: string | null;
    address: string | null;
    network: string | null;
    email: string | null;
}