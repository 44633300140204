import Vue from "vue";
import VueI18n from "vue-i18n";

import { translates } from "./translates";

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: 'ENG',
    messages: translates,
});