import {defineStore} from "pinia";

import {ControllerWeb3Store} from "@/stores/ControllerWeb3.store";
import {WalletAbstract} from "@/stores/Web3Utils/Wallet/Wallet.abstract";
import {CurrenciesStore, ECurrenciesState} from "@/stores/Currencies.store";
import {ESubscriberState, SubscriberStore} from "@/stores/Subscriber.store";
import {EMerchantBillingStatus, EMerchantLoadingState, MerchantStore} from "@/stores/Merchant.store";

import {ApiClient} from "@/stores/ApiUtils";

export const BillingStore = defineStore({
    id: 'billing',

    state: (): IState => ({
        state: EBillingState.NONE,

        merchant: null,
        billing: null,
    }),

    actions: {
        setState(state: EBillingState) {
            this.state = state;
        },
        setMerchant(merchant: any | null) {
            this.merchant = merchant;
        },
        setBilling(billing: any | null) {
            this.billing = billing;
        },

        async load(subscriptionId: string): Promise<void> {
            this.setState(EBillingState.PENDING);

            try {
                const subRes = await ApiClient.get('/billing/' + subscriptionId);

                if(!subRes?.data?.success) {
                    await new Promise(r => setTimeout(r, 1000));

                    return this.load(subscriptionId);
                }

                const {
                    billings,
                    merchants,
                } = subRes.data;

                if(!billings.length) {
                    this.setState(EBillingState.NOT_FOUND);

                    return;
                }

                this.setBilling(billings[0]);
                this.setMerchant(merchants[0]);

                this.setState(EBillingState.SUCCESS);
            } catch (e) {
                await new Promise(r => setTimeout(r, 1000));

                return this.load(subscriptionId);
            }
        },

        async createMock(linkId: string) {
            return await new Promise((resolve, reject) => {
                this.setState(EBillingState.PENDING);

                setTimeout(() => {
                    this.setState(EBillingState.SIGN_TRANSACTION);
                }, 2000)

                setTimeout(() => {
                    this.setState(EBillingState.WAIT_TRANSACTION);
                }, 5000)

                setTimeout(() => {
                    this.setState(EBillingState.CANCELED);
                }, 10000)

                setTimeout(() => {
                    this.setState(EBillingState.SUCCESS);
                    resolve('hello')
                }, 15000)
            })
        },

        async create(linkId: string, onTransaction?: (status: 'OK' | 'FAIL') => void): Promise<[string, string] | undefined> {
            if(this.state !== EBillingState.NONE) {
                return;
            }

            this.setState(EBillingState.PENDING);

            const merchantStore = MerchantStore();
            const web3Store = ControllerWeb3Store();
            const currenciesStore = CurrenciesStore();
            const subscriberStore = SubscriberStore();

            await currenciesStore.wait();
            await merchantStore.waitLoading();
            await subscriberStore.wait();


            if(subscriberStore.state !== ESubscriberState.DONE) {
                this.setState(EBillingState.NONE);

                return;
            }

            if(currenciesStore.state !== ECurrenciesState.DONE) {
                this.setState(EBillingState.NONE);

                return;
            }

            if(merchantStore.loadingState !== EMerchantLoadingState.DONE) {
                this.setState(EBillingState.NONE);

                return;
            }

            const selectedCurrency = merchantStore.getSelectedCurrency();

            if(!selectedCurrency) {
                this.setState(EBillingState.NONE);

                return;
            }

            const spenderAddress = await this._pickSpender(this.merchant!.id, selectedCurrency.networkId);

            this.setState(EBillingState.SIGN_TRANSACTION);

            const txHash = await web3Store._getWallet() // todo: надо будет потом прикрутить получение цены и расчет, а пока считаем что к оплате доступны только стейблы
                .approveAsset(selectedCurrency.contract!, spenderAddress.address, this.merchant!.approveAmountUSD);

            if(!txHash) {
                this.setState(EBillingState.CANCEL_TRANSACTION);

                return;
            }

            switch (txHash) {
                case '-1': // cancel by user
                    //
                    this.setState(EBillingState.CANCEL_TRANSACTION);
                    return;
                case '-3': // insufficient funds of transaction gas too low
                    //
                    this.setState(EBillingState.INSUFFICIENT_TRANSACTION);
                    return;
                case '-2': // unknown error
                    this.setState(EBillingState.FAIL_TRANSACTION);
                    return;
            }


            this.setState(EBillingState.WAIT_TRANSACTION);

            const txState = await this._waitTx(web3Store._getWallet(), txHash);

            if(txState === 'FAIL') {
                if(onTransaction) {
                    onTransaction('FAIL');
                }

                // fail event

                this.setState(EBillingState.NONE);

                return;
            }

            this.setState(EBillingState.WAIT_SUBSCRIPTION);

            // success event
            if(onTransaction) {
                onTransaction('OK');
            }

            const billingLink = await this._create(linkId, selectedCurrency.id, subscriberStore.subscriber!.id, spenderAddress.id);

            if(!billingLink.success) {
                // fail event
                this.setState(EBillingState.ERROR);

                return;
            }

            // success event

            this.setState(EBillingState.SUCCESS);

            return [txHash, billingLink.result.id];
        },

        clear() {
            this.setState(EBillingState.NONE);

            this.setMerchant(null);
            this.setBilling(null);
        },

        async _pickSpender(merchantId: string, networkId: string): Promise<{id: string, address: string}> {
            try {
                const spenderRes = await ApiClient.get(`/merchant/${merchantId}/spender/${networkId}`);

                if (!spenderRes?.data?.success) {
                    await new Promise(r => setTimeout(r, 1000));

                    return this._pickSpender(merchantId, networkId);
                }

                return spenderRes.data.result;
            } catch (e) {
                await new Promise(r => setTimeout(r, 1000));

                return this._pickSpender(merchantId, networkId);
            }
        },

        async _waitTx(wallet: WalletAbstract, tx: string): Promise<'OK' | 'FAIL'> {
            try {
                const state = await wallet.getTransactionStatus(tx);

                if(state === 'PENDING') {
                    await new Promise(r => setTimeout(r, 1000));

                    return this._waitTx(wallet, tx);
                }

                return state;
            } catch (e) {
                await new Promise(r => setTimeout(r, 1000));

                return this._waitTx(wallet, tx);
            }
        },

        async _create(linkId: string, currencyId: string, subscriberId: string, spenderId: string): Promise<{success: boolean, code: number, result: {id: string}}> {
            try {
                const createRes = await ApiClient.post('/merchant/otl/' + linkId, {
                    currencyId: currencyId,
                    subscriberId: subscriberId,
                    spenderId: spenderId,
                });

                return createRes.data;
            } catch (e) {
                return this._create(linkId, currencyId, subscriberId, spenderId);
            }
        }
    },
});

export enum EBillingState {
    NONE,

    SIGN_TRANSACTION,
    CANCEL_TRANSACTION,
    INSUFFICIENT_TRANSACTION,
    FAIL_TRANSACTION,
    WAIT_TRANSACTION,
    WAIT_SUBSCRIPTION,

    PENDING,
    NOT_FOUND,

    DECLINED,
    SUCCESS,
    BLOCKED,
    CANCELED,

    //ERROR статус выставляется только в методе create
    ERROR
}

interface IMerchantBilling {
    id: string;
    merchantId: string;
    currencyId: string;
    networkId: string;
    spenderAddressId: string;
    status: EMerchantBillingStatus,
    createdAt: string;
}

interface IMerchant {
    id: string;
    name: string;
    logoUrl: string | null;
    approveAmountUSD: string;
    returnUrl: string | null;
}

interface IState {
    state: EBillingState;

    merchant: IMerchant | null;
    billing: IMerchantBilling | null;
}
