import web3 from 'web3';
import BigNumber from "bignumber.js";

import {IOnChangeAccountHandler, WalletAbstract} from "@/stores/Web3Utils/Wallet/Wallet.abstract";

import {chains} from "@/stores/Web3Utils/chains";
import {ABI} from "@/stores/Web3Utils/Wallet/abi";
import {wallets} from "@/stores/Web3Utils/Wallet/Wallets/providers-finder";
import {EWeb3Wallet} from "@/stores/Web3Utils";

export class CoinbaseWallet extends WalletAbstract {
    private _onChangeAccount: IOnChangeAccountHandler | undefined;
    private _web3: any = undefined;

    constructor() {
        super();

        this.init();

        // @ts-ignore
        window.lala = this;
    }

    installed(): boolean {
        const eth = this.ethereum();

        return !!eth;
    }

    async checkConnect() {
        if(!this.installed()) {
            return {
                chainId: 1,
                account: null,
            };
        }

        const eth = this.ethereum();

        try {
            const accounts: string[] = await eth.request({method: 'eth_accounts'});

            if(accounts && accounts.length) {
                return {
                    chainId: await this.chainId(),
                    account: accounts[0],
                };
            }

            return {
                chainId: await this.chainId(),
                account: null,
            };
        } catch (e) {
            return {
                    chainId: await this.chainId(),
                    account: null,
                };
        }
    }

    async connect() {
        if(!this.installed()) {
            console.log('fuck')
            return null;
        }

        const alreadyConnectedAccount = await this.checkConnect();

        if(alreadyConnectedAccount?.account) {
            const hasPermissions = await this.hasPermissions(alreadyConnectedAccount.account);

            if(hasPermissions) {
                this.emitChanges();
                console.log('lala', alreadyConnectedAccount);

                return alreadyConnectedAccount;
            }
        }

        const eth = this.ethereum();

        try {
            const accounts: string[] = await eth.request({method: 'eth_requestAccounts'});

            if(accounts && accounts.length) {
                return {
                    chainId: await this.chainId(),
                    account: accounts[0],
                };
            }

            console.log(accounts);

            return null;
        } catch (e) {
            console.log('fuck', e);
            return null;
        }
    }

    async hasPermissions(account: string) {
        if(!this.installed()) {
            return {
                chainId: 1,
                account: null,
            };
        }

        const eth = this.ethereum();

        try {
            const permissions: {caviats: {value: string[]}[]}[] = await eth.request({method: 'wallet_getPermissions'});

            for(const perm of permissions) {
                for(const cav of perm.caviats) {
                    for(const addr of cav.value) {
                        if(account === addr) {
                            return true;
                        }
                    }
                }
            }

            return false;
        } catch (e) {
            return false;
        }
    }

    canChangeChain() {
        return true;
    }

    async changeChainId(chainId: number) {
        if(!this.installed()) {
            return;
        }

        if(chainId === (await this.chainId())) {
            return;
        }

        const eth = this.ethereum();

        try {
            await eth.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(chainId) }],
            });
        } catch (e: any) {
            if (e.code === 4902) {
                await this.addNetwork(chainId);
            }

            return;
        }
    }

    onChangeAccount(handler: IOnChangeAccountHandler) {
        this._onChangeAccount = handler;
    }

    async getBalance() {
        if(!this.installed()) {
            return '0';
        }

        const accountData = await this.checkConnect();

        if(!accountData?.account) {
            return '0';
        }

        const eth = this.ethereum();
        const chainData = chains[accountData.chainId]!;

        try {
            const balanceResponse = await eth.request({
                method: 'eth_getBalance',
                params: [
                    accountData.account,
                ],
            });

            const balance = parseInt(balanceResponse);

            if(!balance) {
                return '0';
            }

            return new BigNumber(balance)
                .div(
                    10 ** Number(chainData.nativeCurrency.decimals),
                )
                .dp(Number(chainData.nativeCurrency.decimals))
                .toFixed();
        } catch (e) {
            return '0';
        }
    }

    async getBalanceSymbol() {
        if(!this.installed()) {
            return '';
        }

        const accountData = await this.checkConnect();

        if(!accountData?.account) {
            return '';
        }

        const chainData = chains[accountData.chainId];

        if(!chainData) {
            return '';
        }

        return chainData.nativeCurrency.symbol;
    }

    async getAssetBalance(contractAddress: string) {
        if(!this.installed()) {
            return '0';
        }

        const accountData = await this.checkConnect();

        if(!accountData?.account) {
            return '0';
        }

        const contract = this.getContract(contractAddress);

        const decimals = await this.getAssetDecimals(contractAddress, contract);

        if(decimals === null) {
            return '0';
        }

        try {
            const balanceRes = await contract
                .methods
                .balanceOf(accountData.account).call();

            const balance = balanceRes;

            return new BigNumber(balance)
                .div(
                    10 ** decimals
                )
                .dp(decimals)
                .toFixed();
        } catch (e) {
            return '0';
        }
    }

    async getAssetDecimals(contractAddress: string, _contract?: any) {
        if(!this.installed()) {
            return null;
        }

        if(!_contract) {
            const accountData = await this.checkConnect();

            if(!accountData?.account) {
                return null;
            }
        }

        if(this.decimalsMap.has(contractAddress)) {
            return this.decimalsMap.get(contractAddress)!;
        }

        const contract = _contract || this.getContract(contractAddress);

        try {
            const decimalsRes = await contract
                .methods
                .decimals()
                .call();

            const decimals = Number(decimalsRes);

            this.decimalsMap.set(contractAddress, decimals);

            return decimals;
        } catch (e) {
            return null;
        }
    }

    async approveAsset(contractAddress: string, spenderAddress: string, amount: string) {
        if(!this.installed()) {
            return null;
        }

        const accountData = await this.checkConnect();

        if(!accountData?.account) {
            return null;
        }

        const contract = this.getContract(contractAddress);

        const decimals = await this.getAssetDecimals(contractAddress, contract);

        if(decimals === null) {
            return null;
        }

        try {
            const approveRes = await contract
                .methods
                .approve(spenderAddress, '0x' + (new BigNumber(amount).multipliedBy(10 ** decimals).dp(0).toString(16)))
                .send({
                    from: accountData.account,
                });

            return approveRes.transactionHash;
        } catch (e: any) {
            if(e && e.code === 4001) { // declined by user
                return '-1';
            } else if(e && e.code === -32603) { // transaction underpriced or gas too low
                return '-3';
            }

            console.log('Transaction error', e);

            return '-2';
        }
    }

    async getTransactionStatus(tx: string) {
        if(!this.installed()) {
            return 'FAIL';
        }

        const accountData = await this.checkConnect();

        if(!accountData?.account) {
            return 'FAIL';
        }

        try {
            const transactionRes = await this._web3.eth
                .getTransactionReceipt(tx);

            if(!transactionRes) {
                // not found or pending

                return 'PENDING';
            }

            return transactionRes.status ? 'OK' : 'FAIL';
        } catch (e) {
            return 'FAIL';
        }
    }



    // private methods

    private init() {
        if(!this.installed()) {
            return;
        }

        const eth = this.ethereum();

        eth.on('connect', this.emitChanges.bind(this));
        eth.on('disconnect', this.emitChanges.bind(this));
        eth.on('chainChanged', this.emitChanges.bind(this));
        eth.on('accountsChanged', this.emitChanges.bind(this));

        this._web3 = new web3(eth);

        // this._web3.eth.getTransactionReceipt('0x8ae15b7ebef9de11921e9a956e267be11b00a0f29a798c66fbf68b1b4aa167e9')
        //     .then((e: any) => console.log('coinbase', e));
    }

    private getContract(contractAddress: string) {
        if(!this.installed()) {
            throw new Error('Metamask is not installed');
        }

        const contract = new this._web3.eth.Contract(ABI, contractAddress);

        return contract;
    }

    private async emitChanges() {
        if(!this._onChangeAccount) {
            return;
        }

        const accountData = await this.checkConnect();

        this._onChangeAccount(accountData || {chainId: await this.chainId(), account: null});
    }

    private async chainId() {
        if(!this.installed()) {
            return 1;
        }

        let chainId: string = '1';

        try {
            chainId = await this.ethereum().request({
                "method": "eth_chainId",
                "params": [],
            });
        } catch (e) {
            //
        }

        return parseInt(chainId || '1');
    }

    private ethereum(): any {
        const _wallets = wallets.get(EWeb3Wallet.COINBASE);

        if(!_wallets) {
            return null;
        }

        const __wallets = Array.from(_wallets!.values());

        return __wallets[0];
    }

    private async addNetwork(chainId: number) {
        if(!this.installed()) {
            return;
        }

        const chain = chains[chainId];

        if(!chain) {
            return;
        }

        const eth = this.ethereum();

        try {
            await eth.request({
                method: 'wallet_addEthereumChain',
                params: [
                    chain,
                ]
            });
        } catch (e) {
            return;
        }
    }
}
