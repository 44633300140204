import {defineStore} from "pinia";

import {CurrenciesStore} from "@/stores/Currencies.store";
import {ControllerWeb3Store} from "@/stores/ControllerWeb3.store";
import {ESubscriberState, SubscriberStore} from "@/stores/Subscriber.store";

import {ApiClient} from "@/stores/ApiUtils";
import {BillingStore} from "@/stores/BillingStore";

let pendingPromise = Promise.resolve();

export const MerchantStore = defineStore({
    id: 'merchant',

    state: (): IState => {
        return {
            loadingState: EMerchantLoadingState.NONE,
            subscribeState: EMerchantSubscribeState.NONE,

            merchant: null,
            currencies: [],
            billings: [],

            selectedCurrency: null,
        };
    },

    actions: {
        setState(state: EMerchantLoadingState) {
            this.loadingState = state;
        },
        setMerchant(merchant: IMerchant | null) {
            const m = Object.assign({}, merchant);

            Object.freeze(m);

            this.merchant = m;
        },
        setCurrencies(currencies: IMerchantCurrency[]) {
            this.currencies = currencies
                .map(currency => {
                    const c = Object.assign({}, currency);

                    Object.freeze(c);

                    return c;
                });
        },
        setSelectedCurrency(currencyId: string | null) {
            if(!currencyId) {
                this.selectedCurrency = null;

                return;
            }

            if(this.selectedCurrency === currencyId) {
                return;
            }

            this.selectedCurrency = currencyId;
        },

        waitLoading() {
            return pendingPromise;
        },

        /**
         * отчистка данных страници коннекта
         * */
        clear() {
            this.setState(EMerchantLoadingState.NONE);
            this.setMerchant(null);
            this.setCurrencies([]);
            this.setSelectedCurrency(null);
        },
        /**
         * загрузка данных мерча (НЕ НАДО ЕГО ДОЖИДАТЬСЯ, НАДО ОРИЕНТИРОВАТЬСЯ НА loadingState)
         * */
        async load(linkId: string) {
            console.log("load linkId ---> ", linkId)
            pendingPromise = pendingPromise
                .then(() => this._load(linkId));
        },
        /**
         * получение монеты в которой пользователь хочет оплатить
         * */
        getSelectedCurrency() {
            return this.getMergedCurrencies()
                .find(c => c.selected) || null;
        },
        /**
         * Установка выбранной монеты для оформления подписки
         * */
        changeSelectedCurrency(currencyId: string) {
            this.setSelectedCurrency(currencyId);

            if(!this.selectedCurrency) {
                return;
            }

            const selectedCurrency = this.getSelectedCurrency()

            if(!selectedCurrency) {
                this.setSelectedCurrency(null);

                return;
            }

            const currenciesStore = CurrenciesStore();

            const web3network = currenciesStore.getWeb3Network(selectedCurrency.networkId);

            const web3Store = ControllerWeb3Store();

            if(web3Store.network === web3network) {
                return;
            }

            web3Store.changeNetwork(web3network, true);
        },
        /**
         * склеиный массив всех монет и монет подписки
         * */
        getMergedCurrencies() {
            const currenciesStore = CurrenciesStore();

            if(currenciesStore.state !== EMerchantLoadingState.DONE) {
                return [];
            }

            const currenciesMap = currenciesStore.getAsMap();

            const web3Store = ControllerWeb3Store();

            const _network = currenciesStore.getServiceNetwork(web3Store.network);

            const billings = this.billings
                .reduce<{[key: string]: boolean}>((acc, billing) => {
                    acc[billing.currencyId + ':' + billing.networkId] = true;

                    return acc;
                }, {});

            return this.currencies
                .map(({id, currencyId, networkId}) => {
                    const currencyData = currenciesMap[currencyId];
                    const networkData = currenciesMap[currencyId]?.networks[networkId];

                    const billing = !!billings[currencyId + ':' + networkId];

                    const balance = _network && _network.id === networkData.id ? (+(web3Store.getBalance(networkData?.contract || ''))).toFixed(2) : '';

                    return {
                        id: id,

                        currencyId: currencyId,
                        currencyTicker: currencyData?.currency || null,
                        currencyAlias: currencyData?.alias || null,

                        networkId: networkId,
                        networkName: networkData?.name || null,
                        networkAlias: networkData?.alias || null,

                        contract: networkData?.contract,

                        available: (networkData?.allowDeposit && !billing) || false,

                        selected: id === this.selectedCurrency,

                        price: currencyData ? currencyData.getPrice('USD') : '0',
                        balance: balance,
                    };
                });
        },

        async _load(linkId: string): Promise<void> {
            this.setState(EMerchantLoadingState.PENDING);

            try {
                const linkRes = await ApiClient.get('/merchant/otl/' + linkId);

                if(!linkRes.data?.success) {
                    if(linkRes.data?.code === 2005) {
                        window.location.href = '/';

                        return;
                    }

                    if(linkRes.data?.code === 3002) {
                        this.setState(EMerchantLoadingState.NOT_FOUND);

                        return;
                    }

                    await new Promise(r => setTimeout(r, 1000));

                    return this._load(linkId);
                }

                const {merchant, currencies} = linkRes.data.result;

                this.setMerchant(merchant);
                this.setCurrencies(currencies);

                await this._loadBillings();

                const billingStore = BillingStore();

                billingStore.setMerchant(this.merchant);

                this.setState(EMerchantLoadingState.DONE);
            } catch (e) {
                await new Promise(r => setTimeout(r, 1000));

                return this._load(linkId);
            }
        },

        async _loadBillings(): Promise<void> {
            if(!this.merchant) {
                return;
            }

            const merchantId = this.merchant.id;

            const subscriberStore = SubscriberStore();

            if(subscriberStore.state !== ESubscriberState.DONE) {
                return;
            }

            const subscriberId = subscriberStore.id!;

            try {
                const billingsRes = await ApiClient.get('/billing/' + subscriberId + '/' + merchantId);

                if(!billingsRes.data?.success) {
                    await new Promise(r => setTimeout(r, 1000));

                    return this._loadBillings();
                }

                this.billings = billingsRes.data.result;
            } catch (e) {
                await new Promise(r => setTimeout(r, 1000));

                return this._loadBillings();
            }
        },
    },
});

export enum EMerchantSubscribeState {
    NONE,
}

export enum EMerchantBillingStatus {
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    BLOCKED = 'BLOCKED',
    CANCELED = 'CANCELED',
    DECLINED = 'DECLINED',
}

interface IMerchantBilling {
    id: string;
    merchantId: string;
    currencyId: string;
    networkId: string;
    spenderAddressId: string;
    status: EMerchantBillingStatus,
    createdAt: string;
}

interface IMerchant {
    id: string;
    name: string;
    logoUrl: string | null;
    approveAmountUSD: string;
    returnUrl: string | null;
}
interface IMerchantCurrency {
    id: string;
    currencyId: string;
    networkId: string;
}

export enum EMerchantLoadingState {
    NONE,
    PENDING,
    DONE,
    NOT_FOUND,
}

interface IState {
    loadingState: EMerchantLoadingState;
    subscribeState: EMerchantSubscribeState;

    merchant: IMerchant | null;
    currencies: IMerchantCurrency[];
    billings: IMerchantBilling[];

    selectedCurrency: string | null;
}