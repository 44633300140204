import Vue from "vue";

import {CurrenciesStore} from "@/stores/Currencies.store";

import moment from "moment";

const memo = new Map();

export const getCurrency = (q) => {
    if(memo.has('getCurrency:' + q)) {
        return memo.get('getCurrency:' + q);
    }

    const currenciesStore = CurrenciesStore();

    const currency = currenciesStore.list.find(c => c.id === q || c.alias === q || c.currency === q);

    if(!currency) {
        return null;
    }

    memo.set('getCurrency:' + q, currency || null);

    return currency;
}
export const currencyTicker = (q) => {
    const currency = getCurrency(q);

    return currency?.currency;
};
export const currencyAlias = (q) => {
    const currency = getCurrency(q);

    return currency?.alias;
};
export const currencyColor = (q) => {
    const currency = getCurrency(q);

    return currency?.group?.primaryColor;
};
export const currencyPrice = (q) => {
    const currency = getCurrency(q);
    return currency?.price;
};
export const currencyNetwork = (currencyId, networkId) => {
    const currency = getCurrency(currencyId);

    return currency?.networks.find((i) => i.id === networkId);
}

export const formatDate = (value, format = 'DD.MM.YYYY') => {
    return value && moment(value).format(format);
};

export const truncateString = (str) => {
    return (typeof str === 'string') ? str.substr(0, 5) + '****' + str.substr(-3) : undefined;
};

Vue.filter('currencyTicker', currencyTicker);
Vue.filter('currencyAlias', currencyAlias);
Vue.filter('currencyPrice', currencyPrice);
Vue.filter('currencyNetwork', currencyNetwork);
Vue.filter('formatDate', formatDate);
Vue.filter('truncateString', truncateString);