import {defineStore} from "pinia";

import {EWeb3Network, EWeb3Wallet} from "@/stores/Web3Utils";
import {WalletAbstract} from "@/stores/Web3Utils/Wallet/Wallet.abstract";
import {MetamaskWallet, TrustWallet, TronlinkWallet, CoinbaseWallet} from "@/stores/Web3Utils/Wallet/Wallets";

import {SubscriberStore} from "@/stores/Subscriber.store";
import {CurrenciesStore, ECurrenciesState} from "@/stores/Currencies.store";
import {EMerchantLoadingState, MerchantStore} from "@/stores/Merchant.store";

const ETH_WALLETS = () => [ // перечень кошельков для эфир-форков
    EWeb3Wallet.COINBASE,
    EWeb3Wallet.METAMASK,
    EWeb3Wallet.TRUSTWALLET,
];
const TRON_WALLETS = () => [ // перечень кошельков для tron`a
    EWeb3Wallet.TRONLINK,
];
const ETH_NETWORKS = () => [ // перечень сетей для эфир-форков
    EWeb3Network.ETH,
    EWeb3Network.BSC,
];
const TRON_NETWORKS = () => [ // перечень сетей для tron`a
    EWeb3Network.TRON,
];

const ALL_WALLETS = () => ([] as EWeb3Wallet[]).concat(ETH_WALLETS(), TRON_WALLETS());
const ALL_NETWORKS = () => ([] as EWeb3Network[]).concat(ETH_NETWORKS(), TRON_NETWORKS());

const AVAILABLE_NETWORKS = (state: any) => {
        const currenciesStore = CurrenciesStore();

        if(currenciesStore.state !== ECurrenciesState.DONE) {
            // нужно дождаться монет
            return [];
        }

        const merchantStore = MerchantStore();

        if(merchantStore.loadingState === EMerchantLoadingState.NONE || merchantStore.loadingState === EMerchantLoadingState.NOT_FOUND) {
            // если не началась загрузка или данных мерча нет или истекла ссылка, то показываем все доступные сети
            return ALL_NETWORKS();
        }

        if(merchantStore.loadingState !== EMerchantLoadingState.DONE) {
            // если данные мерча еще грузятся, то ждем их
            return [];
        }

        const networksSet = new Set<EWeb3Network>();

        for(const currency of merchantStore.getMergedCurrencies()) {
            const network = currenciesStore.getWeb3Network(currency.networkId);

            if(network === null) {
                continue;
            }

            networksSet.add(network);
        }

        if(!networksSet.has(state.network)) {
            const web3 = ControllerWeb3Store();

            web3.disconnect();
        }

        return Array.from(networksSet);
    };
const AVAILABLE_WALLETS = (state: any) => {
    const availableNetworks = AVAILABLE_NETWORKS(state);

    if(!availableNetworks.length) {
        // нет сетей - нет кошельков
        return [];
    }

    const ethNetworks = ETH_NETWORKS();
    const tronNetworks = TRON_NETWORKS();
    const ethWallets = ETH_WALLETS();
    const tronWallets = TRON_WALLETS();

    const walletsSet = new Set<EWeb3Wallet>();

    for(const network of availableNetworks) {
        if(ethNetworks.indexOf(network) > -1) {
            for(const wallet of ethWallets) {
                walletsSet.add(wallet);
            }
        }

        if(tronNetworks.indexOf(network) > -1) {
            for(const wallet of tronWallets) {
                walletsSet.add(wallet);
            }
        }
    }

    return Array.from(walletsSet);
};

const walletsMap = new Map<EWeb3Wallet, WalletAbstract>();

const defaultWallet = EWeb3Wallet.TRONLINK;
const defaultNetwork = EWeb3Network.TRON;

let checkConnectInterval = 0;
let checkBalanceInterval = 0;
let initPromise: undefined | Promise<void> = undefined;

export const ControllerWeb3Store = defineStore({
    id: 'web3',

    state: (): IState => ({
        initialized: false,
        state: EWeb3State.NONE,

        disconnected: false,

        wallet: defaultWallet,
        network: defaultNetwork,

        account: null,
        accountBalance: '0',
        accountBalanceSymbol: '',

        balancesMap: new Map<string, string>(),

        pending: false
    }),

    getters: {
        ALL_WALLETS,
        ALL_NETWORKS,

        ETH_WALLETS,
        TRON_WALLETS,

        ETH_NETWORKS,
        TRON_NETWORKS,

        AVAILABLE_NETWORKS,
        AVAILABLE_WALLETS,

    },

    actions: {
        _setValue(key: string, value: any) {
            if(typeof value === "undefined") {
                window.localStorage.removeItem(key);

                return;
            }

            window.localStorage.setItem(key, JSON.stringify(value));
        },
        _getValue(key: string): any {
            const val = window.localStorage.getItem(key);

            if(val === null) {
                return undefined;
            }

            try {
                return JSON.parse(val);
            } catch (e) {
                return undefined;
            }
        },

        /**
         *
         * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
         * !!!                            !!!
         * !!! для фронтов:               !!!
         * !!!                            !!!
         * !!! НЕ ИСТОЛЬЗОВАТЬ ЭТИ МЕТОДЫ !!!
         * !!!                            !!!
         * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
         *
         * */
        setState(state: EWeb3State) {
            if(this.state === state) {
                return;
            }

            this.state = state;
        },
        setNetwork(network: EWeb3Network) {
            if(this.network === network) {
                return;
            }

            if(!EWeb3Network[network]) {
                network = defaultNetwork;
            }

            this.network = network;

            this._setValue('W3_NETWORK', this.network);
        },
        setWallet(wallet: EWeb3Wallet) {
            if(this.wallet === wallet) {
                return;
            }

            if(!EWeb3Wallet[wallet]) {
                wallet = defaultWallet;
            }

            this.wallet = wallet;

            this._setValue('W3_WALLET', this.wallet);
        },
        setAccount(account: string | null) {
            if(this.account === account) {
                return;
            }

            this.account = account;
        },
        setDisconnected(disconnected: boolean) {
            this.disconnected = disconnected;
        },


        /**
         *
         * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
         * !!!                        !!!
         * !!! для фронтов:           !!!
         * !!!                        !!!
         * !!! ЭТИ ИСПОЛЬЗОВАТЬ МОЖНО !!!
         * !!!                        !!!
         * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
         *
         * */
        changeWallet(wallet: EWeb3Wallet, force = false) {
            if(!EWeb3Wallet[wallet]) {
                return;
            }

            if(!force) {
                if(this.wallet === wallet) {
                    return;
                }
            }

            this.setWallet(wallet);

            switch (this.wallet) {
                case EWeb3Wallet.COINBASE:
                case EWeb3Wallet.METAMASK:
                case EWeb3Wallet.TRUSTWALLET: {
                    const networks = this.AVAILABLE_NETWORKS.filter(n => this.ETH_NETWORKS.includes(n));

                    if(networks.indexOf(this.network) === -1) {
                        this.setNetwork(networks[0]);
                    }
                }break;
                case EWeb3Wallet.TRONLINK: {
                    const networks = this.AVAILABLE_NETWORKS.filter(n => this.TRON_NETWORKS.includes(n));

                    if(networks.indexOf(this.network) === -1) {
                        this.setNetwork(networks[0]);
                    }
                }break;
            }

            this._checkInstalled();
            this._checkConnect(true);
        },
        changeNetwork(network: EWeb3Network | null, byClient = true) {
            if(network === null) {
                return;
            }

            if(!EWeb3Network[network]) {
                if(this.state !== EWeb3State.UNSUPPORTED_NETWORK) {
                    this.setState(EWeb3State.UNSUPPORTED_NETWORK);

                    return;
                }
            }

            if(this.network === network) {
                return;
            }

            this.setNetwork(network);

            switch (this.network) {
                case EWeb3Network.ETH:
                case EWeb3Network.BSC: {
                    if(this.ETH_WALLETS.indexOf(this.wallet) === -1) {
                        this.setWallet(this.ETH_WALLETS[0]);
                    }
                }break;
                case EWeb3Network.TRON: {
                    if(this.TRON_WALLETS.indexOf(this.wallet) === -1) {
                        this.setWallet(this.TRON_WALLETS[0]);
                    }
                }break;
            }

            this._checkInstalled();
            this._checkConnect(byClient);

            return true;
        },
        getScanName() {
            switch (this.network) {
                case EWeb3Network.TRON:
                    return 'Tronscan';
                case EWeb3Network.BSC:
                    return 'BscScan';
                case EWeb3Network.ETH:
                    return 'Etherscan';
            }

            return '';
        },
        getTransactionUrl(tx: string) {
            switch (this.network) {
                case EWeb3Network.TRON:
                    return 'https://tronscan.org/#/transaction/' + tx;
                case EWeb3Network.BSC:
                    return 'https://bscscan.com/tx/' + tx;
                case EWeb3Network.ETH:
                    return 'https://etherscan.io/tx/' + tx;
            }

            return tx;
        },

        canSwitchChain() {
            if(!walletsMap.has(this.wallet)) {
                return false;
            }

            return this._getWallet()
                .canChangeChain();
        },
        doSwitchChain() {
            this.pending = true

            this._getWallet()
                .changeChainId(this.network)
                .then(() => {
                    const subscriberStore = SubscriberStore();

                    subscriberStore.login();

                    this.pending = false;
                })
        },
        cancelSwitchChain() {
            this._checkConnect(false);
        },

        getBalance(asset: string): string {
            if(!asset) {
                return '0';
            }

            if(!this.balancesMap.has(asset)) {
                if(this.state !== EWeb3State.CONNECTED) {
                    return '0';
                }

                this.balancesMap.set(asset, '');

                const wallet = this._getWallet()!;

                wallet.getAssetBalance(asset)
                    .then(e => {
                        this.balancesMap.set(asset, e);
                    });
            }

            return this.balancesMap.get(asset)!;
        },

        connect() {
            if(!this.initialized) {
                return;
            }

            if(this.state !== EWeb3State.NONE) {
                return;
            }

            this.setDisconnected(false);

            this.pending = true

            this._getWallet()
                .connect()
                .then(() => {
                    this.pending = false
                });
        },
        disconnect() {
            this.pending = true

            this.setDisconnected(true);
            this.setState(EWeb3State.NONE);
            this.setAccount(null);

            this.pending = false
        },

        init() {
            if(initPromise) {
                return initPromise;
            }

            initPromise = new Promise<void>((resolve) => {
                window.addEventListener('load', () => {
                    if(this.initialized) {
                        return;
                    }

                    this._bindWallets();

                    this.changeWallet(this._getValue('W3_WALLET'));
                    this.changeNetwork(this._getValue('W3_NETWORK'));

                    this.initialized = true;

                    setInterval(() => {
                        this._checkBalance();
                    }, 1000);

                    this._checkInstalled();
                    this._checkConnect();

                    resolve();
                });
            });
        },

        async _checkBalance() {
            clearTimeout(checkBalanceInterval);

            checkBalanceInterval = setTimeout(async () => {
                if(this.state !== EWeb3State.CONNECTED) {
                    return;
                }

                const wallet = this._getWallet();

                const [balance, symbol] = await Promise.all([
                    wallet.getBalance(),
                    wallet.getBalanceSymbol(),
                ]);

                if(this.accountBalance !== balance) {
                    this.accountBalance = balance;
                }

                if(this.accountBalanceSymbol !== symbol) {
                    this.accountBalanceSymbol = symbol;
                }
            }, 600) as any as number;
        },

        _checkInstalled() {
            const isInstalled = this._getWallet()
                .installed();

            this.setState(isInstalled ? EWeb3State.NONE : EWeb3State.WALLET_NOT_INSTALLED);

            return isInstalled;
        },
        _checkConnect(byClient = false) {
            clearTimeout(checkConnectInterval);

            checkConnectInterval = setTimeout(async () => {
                const connectData = await this._getWallet()
                    .checkConnect();

                // console.log(this.wallet, connectData, walletsMap);

                this._onChangeAccount(connectData, byClient);
            }, 300) as any as number;
        },
        _onChangeAccount(data: {account: string | null, chainId: number} | null, byClient = false) {
            this.balancesMap.clear();

            if(this.state === EWeb3State.WALLET_NOT_INSTALLED) {
                const isInstalled = this._checkInstalled();

                if(!isInstalled) {
                    return;
                }
            }

            // if(this.disconnected) {
            //     return;
            // }

            const account = data?.account || null;
            const chainId = data?.chainId || null;

            this.setAccount(account);

            if(!account) {
                this.setState(EWeb3State.NONE);
            } else {
                this.setState(EWeb3State.CONNECTED);
            }

            if(byClient) {
                if(this.network !== chainId) {
                    this.setState(EWeb3State.CHANGE_CHAIN);

                    return;
                }
            }

            this.changeNetwork(chainId);

            this._checkBalance();

            const subscriberStore = SubscriberStore();

            if(this.account) {
                subscriberStore.login();
            } else {
                subscriberStore.clear();
            }
        },
        _bindWallets() {
            walletsMap.set(EWeb3Wallet.COINBASE, new CoinbaseWallet());
            walletsMap.set(EWeb3Wallet.METAMASK, new MetamaskWallet());
            walletsMap.set(EWeb3Wallet.TRUSTWALLET, new TrustWallet());
            walletsMap.set(EWeb3Wallet.TRONLINK, new TronlinkWallet());

            for(const [wallet, walletInstance] of walletsMap.entries()) {
                walletInstance.onChangeAccount((data) => {
                    if(this.wallet === wallet) {
                        this._onChangeAccount(data);
                    }
                });
            }
        },
        _getWallet() {
            const wallet = walletsMap.get(this.wallet);

            if(!wallet) {
                throw new Error('Unknown wallet selected');
            }

            return wallet;
        }
    },
});

export enum EWeb3State {
    NONE, // ничего происходит
    CONNECTING, // процесс подключения кошелька
    WALLET_NOT_INSTALLED, // выбранный кошелек не установлен
    UNSUPPORTED_NETWORK, // сеть выбранная в кошельке не поддерживается
    CHANGE_CHAIN, // выбранная сеть и сеть кошелька отличаются
    CONNECTED, // кошелек подключен
}

interface IState {
    initialized: boolean;
    state: EWeb3State;

    disconnected: boolean;

    network: EWeb3Network;
    wallet: EWeb3Wallet;

    account: string | null;
    accountBalance: string;
    accountBalanceSymbol: string;

    balancesMap: Map<string, string>;

    pending: boolean;
}