import {EWeb3Wallet} from "@/stores/Web3Utils";

export const providers = new Set<any>();
export const wallets = new Map<EWeb3Wallet, Set<any>>();

(() => {
    const getProviders = (p: any) => {
        if(!p || !Array.isArray(p)) {
            return [];
        }

        return p;
    }
    const appendWallet = (w: EWeb3Wallet, p: any) => {
        if(!wallets.has(w)) {
            wallets.set(w, new Set());
        }

        const _wallets = wallets.get(w)!;

        _wallets.add(p);
    };

    //@ts-ignore
    const ethereum = window.ethereum || {};
    //@ts-ignore
    const original = window.original || {};
    //@ts-ignore
    const otherEthereumProviderArray = window.otherEthereumProviderArray || [];
    //@ts-ignore
    const trustWallet = window.trustwallet ? [window.trustwallet] : [];

    const allProviders = [
        ...getProviders(ethereum.providers),
        ...getProviders(original.providers),
        ...getProviders(otherEthereumProviderArray),
        ...trustWallet,
    ];

    for(const p of allProviders) {
        providers.add(p);

        const childProviders = getProviders(p.providers);

        for(const p of childProviders) {
            providers.add(p);
        }
    }

    for(const p of allProviders.values()) {
        if(p.isCoinbaseWallet) {
            appendWallet(EWeb3Wallet.COINBASE, p);
        } else if(p.isMetaMask && typeof p._metamask === 'object' && !p.isTronLink && !p.isTrust && !p.isTrustWallet) {
            appendWallet(EWeb3Wallet.METAMASK, p);
        } else if(p.isTronLink) { // это импостер
            //
        } else if(p.isTrust && p.isTrustWallet) {
            appendWallet(EWeb3Wallet.TRUSTWALLET, p);
        }
    }

    //@ts-ignore
    window.__providers = providers;
    //@ts-ignore
    window.__wallets = wallets;
})();