<template>
    <div class="app">
        <router-view v-if="initialized"/>
    </div>
</template>

<script>
import {mapActions, mapState} from "pinia/dist/pinia";

import {ControllerWeb3Store} from "@/stores/ControllerWeb3.store";
import {CurrenciesStore} from "@/stores/Currencies.store";

export default {
    computed: {
        ...mapState(ControllerWeb3Store, ['initialized']),
    },
    methods: {
        ...mapActions(ControllerWeb3Store, {
            initWeb3: 'init',
        }),
        ...mapActions(CurrenciesStore, {
            initCurrencies: 'init',
        }),
    },
    created() {
        this.initWeb3();
        this.initCurrencies();
    }
}
</script>

<style scoped lang="scss">
.app {

}
</style>
