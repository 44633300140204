import {defineStore} from "pinia";

import {ApiClient} from "@/stores/ApiUtils";
import {EWeb3Network} from "@/stores/Web3Utils";

let pendingPromise = Promise.resolve();
let pricesPromises: {[key: string]: Promise<void>} = {};

export const CurrenciesStore = defineStore({
    id: 'currencies',

    state: (): IState => {
        return {
            state: ECurrenciesState.NONE,

            prices: {},

            list: [],
        };
    },

    getters: {
        currencies(state): ICurrency[] {
            return state.list.map(currency => {
                return {
                    id: currency.id,
                    currency: currency.currency,
                    alias: currency.alias,
                    orderIndex: currency.orderIndex,
                    isFiat: currency.isFiat,

                    getPrice: currency.getPrice,
                    waitPrice: currency.waitPrice,
                };
            });
        },
        networks(state): INetwork[] {
            const networks = state.list.reduce<{[key: string]: INetwork}>((acc, currency) => {
                for(const network of currency.networks) {
                    if(acc[network.id]) {
                        continue;
                    }

                    acc[network.id] = {
                        id: network.id,
                        name: network.name,
                        alias: network.alias,
                    };
                }

                return acc;
            }, {});

            return Object.values(networks);
        },
    },

    actions: {
        async init() {
            if(this.state !== ECurrenciesState.NONE) {
                return;
            }

            this.state = ECurrenciesState.PENDING;

            pendingPromise = this._fetchCurrencies();

            await pendingPromise;

            this.state = ECurrenciesState.DONE;
        },

        async _fetchCurrencies(attempt = 0): Promise<void> {
            try {
                const currenciesRes = await ApiClient.get('/currencies');

                if(!currenciesRes.data?.success) {
                    await new Promise(r => setTimeout(r, 1000));

                    return this._fetchCurrencies(attempt + 1);
                }

                this.list = currenciesRes.data.result.map((c: any) => {
                    c.getPrice = (to: string): string => {
                        const symbol = c.currency + '/' + to;

                        if(this.prices[symbol]) {
                            return this.prices[symbol];
                        }

                        pricesPromises[symbol] = this._fetchPrice(symbol);

                        return this.prices[symbol];
                    };
                    c.waitPrice = (to: string): Promise<string> => {
                        const symbol = c.currency + '/' + to;

                        if(!pricesPromises[symbol]) {
                            c.getPrice(to);
                        }

                        return pricesPromises[symbol]
                            .then(() => this.prices[symbol]);
                    };

                    return c;
                });

                this.state = ECurrenciesState.DONE;
            } catch (e) {
                await new Promise(r => setTimeout(r, 1000));

                return this._fetchCurrencies(attempt + 1);
            }
        },
        async _fetchPrice(symbol: string): Promise<void> {
            this.prices[symbol] = 'pending';

            try {
                const priceRes = await ApiClient.get('/currencies/' + symbol);

                if(!priceRes.data?.success) {
                    await new Promise(r => setTimeout(r, 1000));

                    return this._fetchPrice(symbol);
                }

                this.prices[symbol] = priceRes.data.result.price;
            } catch (e) {
                await new Promise(r => setTimeout(r, 1000));

                return this._fetchPrice(symbol);
            }
        },

        getServiceNetwork(web3network: EWeb3Network) {
            let name = '';

            switch (web3network) {
                case EWeb3Network.TRON: {
                    name = 'tron';
                }break;
                case EWeb3Network.ETH: {
                    name = 'ethereum';
                }break;
                case EWeb3Network.BSC: {
                    name = 'bsc';
                }break;
                default:
                    return null;
            }

            return this.networks.find(n => n.name === name) || null;
        },
        getWeb3Network(networkId: string) {
            const name = this.networks.find(n => n.id === networkId)?.name;

            switch (name) {
                case 'tron': {
                    return EWeb3Network.TRON;
                }
                case 'ethereum': {
                    return EWeb3Network.ETH;
                }
                case 'bsc': {
                    return EWeb3Network.BSC;
                }
                default:
                    return null;
            }
        },

        getAsMap(): ICurrenciesAsMap {
            return this.list.reduce<any>((acc, c) => {
                acc[c.id] = Object.assign({}, c);
                acc[c.id].networks = c.networks.reduce<any>((acc, n) => {
                    acc[n.id] = Object.assign({}, n);

                    return acc;
                }, {});

                return acc;
            }, {});
        },

        wait(): Promise<void> {
            return pendingPromise;
        }
    },
});

export enum ECurrenciesState {
    NONE,
    PENDING,
    DONE,
}

interface ICurrency {
    id: string;
    currency: string;
    alias: string;
    orderIndex: number;
    isFiat: boolean;

    getPrice(to: string): string;
    waitPrice(to: string): Promise<string>
}

interface INetwork {
    id: string;
    name: string;
    alias: string;
}

interface ICurrenciesAsMap {
    [key: string]: ICurrency & {
        networks: {
            [key: string]: INetwork & {
                isDefault: boolean;
                allowDeposit: boolean;
                contract: string | null;
            };
        };
    };
}

interface IState {
    state: any;

    list: (ICurrency & {
        networks: (INetwork & {
            isDefault: boolean;
            allowDeposit: boolean;
            contract: string | null;
        })[]
    })[];

    prices: {
        [key: string]: string | 'pending';
    };
}