export const chains: IChainMap = {
    "1": {
        "chainName": "Ethereum Mainnet",
        "chainId": '0x' + 1..toString(16),
        // "shortName": "eth",
        // "networkId": 1,
        "nativeCurrency": {"name": "Ether", "symbol": "ETH", "decimals": 18},
        "rpcUrls": ["https://api.mycryptoapi.com/eth", "https://cloudflare-eth.com"],
        // "faucets": [],
        // "infoURL": "https://ethereum.org"
    },
    "56": {
        "chainName": "Binance Smart Chain Mainnet",
        "chainId": '0x' + 56..toString(16),
        // "shortName": "bnb",
        // "networkId": 56,
        "nativeCurrency": {"name": "Binance Chain Native Token", "symbol": "BNB", "decimals": 18},
        "rpcUrls": ["https://bsc-dataseed1.binance.org", "https://bsc-dataseed2.binance.org", "https://bsc-dataseed3.binance.org", "https://bsc-dataseed4.binance.org", "https://bsc-dataseed1.defibit.io", "https://bsc-dataseed2.defibit.io", "https://bsc-dataseed3.defibit.io", "https://bsc-dataseed4.defibit.io", "https://bsc-dataseed1.ninicoin.io", "https://bsc-dataseed2.ninicoin.io", "https://bsc-dataseed3.ninicoin.io", "https://bsc-dataseed4.ninicoin.io", "wss://bsc-ws-node.nariox.org"],
        // "faucets": ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
        // "infoURL": "https://www.binance.org"
    },
};

interface IChain {
    chainName: string;
    chainId: string;
    // shortName: string;
    // networkId: number;
    nativeCurrency: {
        name: string;
        symbol: string;
        decimals: number;
    };
    rpcUrls: string[];
    // faucets: string[];
    // infoURL: string;
}
interface IChainMap {
    [key: string]: IChain;
}