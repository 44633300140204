import BigNumber from "bignumber.js";

import {IOnChangeAccountHandler, WalletAbstract} from "@/stores/Web3Utils/Wallet/Wallet.abstract";
import {EWeb3Network} from "@/stores/Web3Utils";
import {ABI} from "@/stores/Web3Utils/Wallet/abi";

export class TronlinkWallet extends WalletAbstract {
    private initialized = false;
    private _onChangeAccount: IOnChangeAccountHandler | undefined;

    constructor() {
        super();

        this.init();

        // @ts-ignore
        window.lalala = this;
    }

    installed() {
        const trx = this.tronweb();
        const link = this.tronlink();

        return !!(trx && link);
    }

    async checkConnect() {
        if(!this.installed()) {
            return {
                chainId: EWeb3Network.TRON,
                account: null,
            };
        }

        const link = this.tronlink();

        try {
            if(!link.ready) {
                return {
                    chainId: EWeb3Network.TRON,
                    account: null,
                };
            }

            const account = this.account();

            if(!account) {
                return {
                    chainId: EWeb3Network.TRON,
                    account: null,
                };
            }

            return {
                chainId: EWeb3Network.TRON,
                account: account,
            };
        } catch (e) {
            return {
                chainId: EWeb3Network.TRON,
                account: null,
            };
        }
    }

    async connect() {
        if(!this.installed()) {
            return null;
        }

        const alreadyConnectedAccount = await this.checkConnect();

        if(alreadyConnectedAccount?.account) {
            return alreadyConnectedAccount;
        }

        const trx = this.tronlink();

        try {
            const res = await trx.request({method: 'tron_requestAccounts'});

            if(res && res.code === 200) {
                return this.checkConnect();
            }

            return null;
        } catch (e) {
            return null;
        }
    }

    canChangeChain() {
        return false;
    }

    async changeChainId() {
        return;
    }

    onChangeAccount(handler: IOnChangeAccountHandler) {
        this._onChangeAccount = handler;
    }

    async getBalance() {
        if(!this.installed()) {
            return '0';
        }

        const account = this.account();

        if(!account) {
            return '0';
        }

        const trx = this.tronweb();

        try {
            const accountData = await trx.trx.getAccount(account);

            const balance = accountData?.balance || 0;

            if(!balance) {
                return '0';
            }

            return BigNumber(balance)
                .div(1000000)
                .dp(6)
                .toFixed();
        } catch (e) {
            return '0';
        }
    }

    async getBalanceSymbol() {
        return 'TRX';
    }

    async getAssetBalance(contractAddress: string) {
        if(!this.installed()) {
            return '0';
        }

        const account = this.account();

        if(!account) {
            return '0';
        }

        const contract = this.getContract(contractAddress);

        const decimals = await this.getAssetDecimals(contractAddress, contract);

        if(decimals === null) {
            return '0';
        }

        try {
            const balanceRes = await contract.balanceOf(account).call();

            const balance = balanceRes._hex;

            return new BigNumber(balance)
                .div(
                    10 ** decimals
                )
                .dp(decimals)
                .toFixed();
        } catch (e) {
            return '0';
        }
    }

    async getAssetDecimals(contractAddress: string, _contract?: any) {
        if(!this.installed()) {
            return null;
        }

        if(!_contract) {
            const account = await this.account();

            if(!account) {
                return null;
            }
        }

        if(this.decimalsMap.has(contractAddress)) {
            return this.decimalsMap.get(contractAddress)!;
        }

        const contract = _contract || this.getContract(contractAddress);

        try {
            const decimalsRes = await contract
                .methods
                .decimals()
                .call();

            const decimals = Number(decimalsRes);

            this.decimalsMap.set(contractAddress, decimals);

            return decimals;
        } catch (e) {
            return null;
        }
    }

    async approveAsset(contractAddress: string, spenderAddress: string, amount: string) {
        if(!this.installed()) {
            return null;
        }

        const account = await this.account();

        if(!account) {
            return null;
        }

        const contract = this.getContract(contractAddress);

        const decimals = await this.getAssetDecimals(contractAddress, contract);

        if(decimals === null) {
            return null;
        }

        try {
            const approveRes = await contract
                .approve(spenderAddress, '0x' + (new BigNumber(amount).multipliedBy(10 ** decimals).dp(0).toString(16)))
                .send({
                    from: account,
                });

            return approveRes;
        } catch (e: any) {
            if(e === 'Confirmation declined by user') {
                return '-1';
            } else if(e && e.message === 'Account resource insufficient error.') {
                return '-3';
            } else if(e && e.message && e.message.indexOf('account') !== -1 && e.message.indexOf('does not exist') !== -1) { // аккаунт
                return '-3';
            }

            console.log('Transaction error', e);

            return '-2';
        }
    }

    async getTransactionStatus(tx: string) {
        if(!this.installed()) {
            return 'FAIL';
        }

        const account = await this.account();

        if(!account) {
            return 'FAIL';
        }

        const trx = this.tronweb();

        try {
            const transactionRes = await trx.trx
                .getTransactionInfo(tx);

            if(!transactionRes.id) {
                // not found or pending

                return 'PENDING';
            }

            return transactionRes.receipt.result === 'SUCCESS' ? 'OK' : 'FAIL';
        } catch (e) {
            return 'FAIL';
        }
    }



    // private methods

    private init() {
        if(!this.installed()) {
            return;
        }

        window.addEventListener('message', (e) => {
            if(e?.data?.message?.action) {
                switch (e.data.message.action) {
                    case 'accountsChanged':
                    case 'setNode':
                    case 'connect':
                    case 'disconnect':
                        this.emitChanges();
                        break;
                }
            }
        });

        this.initialized = true;
    }

    private getContract(contractAddress: string) {
        if(!this.installed()) {
            throw new Error('Tronlink is not installed');
        }

        const trx = this.tronweb();

        const contract = trx.contract(ABI, contractAddress);

        return contract;
    }

    private async emitChanges() {
        if(!this._onChangeAccount) {
            return;
        }

        if(!this.initialized) {
            return;
        }

        const accountData = await this.checkConnect();

        this._onChangeAccount(accountData || {chainId: EWeb3Network.TRON, account: null});
    }

    private tronlink(): any {
        // @ts-ignore
        return window.tronLink;
    }
    private tronweb(): any {
        // @ts-ignore
        return window.tronWeb;
    }

    private account(): string | null {
        if(!this.installed()) {
            return null;
        }

        const trx = this.tronweb();
        const link = this.tronlink();

        if(!link.ready) {
            return null;
        }

        if(trx.defaultAddress && trx.defaultAddress.base58) {
            return trx.defaultAddress.base58;
        }

        return null;
    }
}
