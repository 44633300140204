export abstract class WalletAbstract {
    protected decimalsMap = new Map<string, number>();

    installed(): boolean {
        return false;
    }

    installLink(): string {
        return '';
    }

    async checkConnect(): Promise<IConnectResult | null> {
        return null;
    }

    async connect(): Promise<IConnectResult | null> {
        return null;
    }

    canChangeChain(): boolean {
        return false;
    }
    async changeChainId(chainId: number): Promise<void> {
        return;
    }

    async getBalance(): Promise<string> {
        return '0';
    }
    async getBalanceSymbol(): Promise<string> {
        return '';
    }

    async getAssetBalance(contract: string): Promise<string> {
        return '0';
    }

    async getAssetDecimals(contractAddress: string, contract?: any): Promise<number | null> {
        return null;
    }

    async approveAsset(contract: string, spender: string, amount: string): Promise<string | null> {
        return null;
    }

    async getTransactionStatus(tx: string): Promise<'OK' | 'PENDING' | 'FAIL'> {
        return 'FAIL';
    }

    onChangeAccount(handler: IOnChangeAccountHandler): void {}
}

export interface IConnectResult {
    chainId: number;
    account: string | null;
}

export interface IOnChangeChainIdHandler {
    (chainId: number): void;
}
export interface IOnChangeAccountHandler {
    (connectResult: IConnectResult | null): void;
}