export const ENG = {
    'common': {
      'empty': 'Empty list'
    },
    'buttons': {
        'close': 'Close'
    },
    'main-menu': {
        'titles': {
            "subscriptions": "My subscriptions",
            "providers": "My providers",
            "history": "History of write-offs",
            "settings": "Settings"
        }
    },
    'header': {
        'action': {
            'button_label': {
                'change_network': 'Change network',
                'connect': 'Connect',
                'disconnect': 'Disconnect',
                'create_wallet': 'Install wallet'
            }
        }
    },
    'pages': {
        'subscriptions': {
            'list': {
                'title': 'My subscribes'
            },
            'new': {
                'dashboard': {
                    'title': 'New Subscription',
                    'button_label': {
                        'connect_wallet': 'Connect Wallet',
                        'give_permission': 'Give a permission to subscribe',
                    }
                },
                'bad_link_dashboard': {
                    'title': 'Subscription is invalid',
                    'description': 'Your subscription is no longer valid. Create a new payment or contact technical support.'
                },
                'modals': {
                    'add': {
                        'available_networks': 'Available networks',
                        'available_wallets': 'Available wallets',
                        'button_label': {
                            'change_network': 'Change network',
                            'connect': 'Connect',
                            'disconnect': 'Disconnect',
                            'create_wallet': 'Install wallet',

                        }
                    },
                    'sign': {
                        'title': 'Sign Transaction',
                        'description': 'Please, sign transaction in your wallet',
                    },
                    'sent': {
                        'title': 'Transaction sent',
                        'description': 'Transaction sent. Please wait for confirmation.',
                        'button_label': 'Back to merchant site',
                    },
                    'success': {
                        'title': 'Successful Subscription',
                        'description': 'Check transaction on ',
                        'button_label': 'Back to merchant site',
                    },
                    'fail': {
                        'cancel': {
                            'title': 'Transaction canceled',
                            'description': 'The transaction was canceled in the wallet',
                        },
                        'insufficient': {
                            'title': 'Insufficient fund',
                            'description': 'Maybe you don\'t have enough {coin} in your wallet to send the transaction, or you set the gas price too low.',
                        },
                        'unknown': {
                            'title': 'Fail sent transaction',
                            'description': 'Try again or contact our support.',
                        },
                        'error': {
                            'title': 'Transaction failed',
                            'description': 'Try again or contact our support.',
                        },
                    },
                }
            },
        },

    }
}
